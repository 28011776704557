<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3
          class="card-label font-weight-bolder text-dark"
          v-if="record.id != -1"
        >
          Edit Action
        </h3>
        <h3
          class="card-label font-weight-bolder text-dark"
          v-if="record.id == -1"
        >
          Create Action
        </h3>
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          Save Changes
        </button>
        <button type="reset" class="btn btn-secondary" @click="cancel()">
          Cancel
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Framework Item</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-form-select
                v-model="selectedFrameworkItemIDSelected"
                :options="frameworkItems"
            ></b-form-select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Reference</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-input
              ref="reference_id"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model.trim="$v.actionRecord.reference_id.$model"
            />
            <div class="error" v-if="!$v.actionRecord.reference_id.required">
              <i>Reference is required</i>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Action</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-textarea
              ref="action"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model.trim="$v.actionRecord.action.$model"
            />
            <div class="error" v-if="!$v.actionRecord.action.required">
              <i>Action is required</i>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Status</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-input
              ref="status"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="actionRecord.status"
            />
          </div>
        </div>


        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >How</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-textarea
              ref="how"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model.trim="$v.actionRecord.how.$model"
            />
            <div class="error" v-if="!$v.actionRecord.how.required">
              <i>How is required</i>
            </div>
          </div>
        </div>


        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >When</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-form-datepicker
              ref="when_date"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="actionRecord.when_date"
            />
            <div class="error" v-if="!$v.actionRecord.when_date.required">
              <i>When is required</i>
            </div>            
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Who</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-form-select
                ref="who_id"
                v-model="actionRecord.who_id"
                :options="getUserData"
            ></b-form-select>
            <div class="error" v-if="!$v.actionRecord.who_id.required">
              <i>Who is required</i>
            </div>                         
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Validation</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-textarea
              ref="validation"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="actionRecord.validation"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Review Date</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-form-datepicker
              ref="review_date"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="actionRecord.review_date"
            />
          </div>
        </div>
      </div>
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  UPDATE_FRAMEWORKACTION_DETAILS,
  CREATE_FRAMEWORKACTION_DETAILS,
}  from "@/core/services/store/frameworkactions.module";


import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  components: {},
  mixins: [validationMixin],
  name: "PersonalInformation",
  props: {
    record: Object,
    selectedFrameworkItemID: String,
    frameworkItems:Array,
  },
  data() {
    return {
      actionRecord: this.record,
      selectedFrameworkItemIDSelected: this.selectedFrameworkItemID
    };
  },
  validations: {
    actionRecord: {
      reference_id: {
        required,
      },
      action: {
        required,
      },
      how: {
        required,
      },
      when_date: {
        required,
      },
      who_id: {
        required,
      },
    },
  },
  mounted() {
  },
  methods: {
    save() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {

        var reference_id = this.$refs.reference_id.value;
        var action = this.$refs.action.value;
        var status = this.$refs.status.value;
        var how = this.$refs.how.value;
        var when_date = this.$refs.when_date.value;
        var who_id = this.$refs.who_id.value;
        var validation = this.$refs.validation.value;
        var review_date = this.$refs.review_date.value;
        var id = this.actionRecord.id;
        var framework_id = this.selectedFramework;
        var framework_requirement_response_id= this.selectedFrameworkItemIDSelected;

        // set spinner to submit button
        const submitButton = this.$refs["kt_save_changes"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        if (id == -1) {
          // send update request
          this.$store
            .dispatch(CREATE_FRAMEWORKACTION_DETAILS, {
              id,
              reference_id,
              action,
              status,
              how,
              when_date,
              who_id,
              validation,
              review_date,
              framework_id,
              framework_requirement_response_id
            })
            .then(() => {
              this.$emit("finished");
            });
        } else {
          // send update request

          this.$store
            .dispatch(UPDATE_FRAMEWORKACTION_DETAILS, {
              id,
              reference_id,
              action,
              status,
              how,
              when_date,
              who_id,
              validation,
              review_date,
              framework_id,
              framework_requirement_response_id

            })
            .then(() => {
              this.$emit("finished");
            });
        }

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }
    },
    cancel() {
      this.$emit("canceled");
    },
  },
  computed: {
    ...mapGetters(["currentUser","selectedFramework","currentUsersData"]),
    getUserData() {
      var users = [];
      if (this.currentUsersData.users) {
        console.log(this.currentUsersData.users)
        this.currentUsersData.users.forEach(user => {
          users.push({value:user.id,text:user.name})
          
        });
      }
      return users
    }    
  },
  watch: {
    record: function(newVal, oldVal) {
      // watch it
      console.log("Prop changed: ", newVal, " | was: ", oldVal);
    },
  },
};
</script>
<style>
.error i {
  color: #cc0000;
}
</style>
